<template>
  <b-form>
    <b-form-row :class="rowClass">
      <b-col>
        Name:
      </b-col>
      <b-col>
        <b-form-input
          v-model="value.name"
          placeholder="name"
        />
      </b-col>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-col>
        Description:
        <p class="font-small-1">
          Used to promote your location on the Breeze website
        </p>
      </b-col>
      <b-col>
        <Translateable
          v-model="value"
          field="description"
          :rows="6"
          field-type="textarea"
          placeholder="Description"
        />
      </b-col>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-col>
        Short description:
        <p class="font-small-1">
          Used in showcases mails or in the app. Max 256 characters.
        </p>
      </b-col>
      <b-col>
        <Translateable
          v-model="value"
          field="shortDescription"
          field-type="textarea"
          :max-length="256"
          placeholder="Short description (Max 256 characters.)"
        />
      </b-col>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-col>
        Photo:
      </b-col>
      <b-col>
        <b-row
          v-if="value.photo"
          class="mb-1"
        >
          <b-col>
            <div
              class="wrapper"
            >
              <b-img
                v-if="value.photo"
                right
                style="width: 100%"
                :src="value.photo.withStoragePath(false)"
                alt="Center image"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <UploadCropper @upload-complete="setPhoto">
              Upload new
            </UploadCropper>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-col>
        Phonenumber:
      </b-col>
      <b-col>
        <b-form-input
          v-model="value.phone"
          placeholder="Phonenumber"
        />
      </b-col>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-col>
        Email adress:
      </b-col>
      <b-col>
        <b-form-input
          v-model="value.email"
          placeholder="Email adress"
        />
      </b-col>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-col>
        Adress:
      </b-col>
      <b-col>
        <b-form-input
          v-model="value.address"
          placeholder="Adress"
        />
      </b-col>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-col>
        Postalcode:
      </b-col>
      <b-col>
        <b-form-input
          v-model="value.postalCode"
          placeholder="Postalcode"
        />
      </b-col>
    </b-form-row>
    <b-form-row :class="rowClass">
      <b-col>
        City
      </b-col>
      <b-col>
        <CitySelect
          v-model="value.cityId"
        />
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import CitySelect from '@/components/Fields/CitySelect.vue'
import UploadCropper from '@/components/Fields/UploadCropper.vue'
import Translateable from '@/components/Fields/Translateable.vue'

export default {
  name: 'LocationForm',
  components: {
    Translateable,
    UploadCropper,
    CitySelect,
  },
  props: {
    value: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    const photo = new File([], '')
    return {
      rowClass: 'mb-1',
      photo,
    }
  },
  watch: {
    value() {
      this.$emit('value', this.value)
    },
  },
  methods: {
    setPhoto(photo) {
      this.value.photo = photo
      this.value.photoId = photo.id
      this.$emit('save')
    },
  },
}
</script>

<style scoped>
.wrapper {
  flex-grow: 0;
}

.top-right {
  position: absolute;
  top: -4px;
  right: -4px;
}
</style>
