<template>
  <Account />
</template>

<script>
import Account from '@/components/account/Account.vue'

export default {
  components: {
    Account,
  },
}
</script>
