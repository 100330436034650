<template>
  <b-container>
    <h1>My Account</h1>
    <b-row>
      <b-col>
        <Location />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Location from '@/components/account/Location.vue'

export default {
  name: 'Account',
  components: {
    Location,
  },
}
</script>
