<template>
  <b-form-select
    :value="value"
    :options="cityOptions"
    @input="$emit('input', $event)"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CitySelect',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      cities: 'cities/cities',
    }),
    cityOptions() {
      return this.cities.map(city => ({ value: city.id, text: city.name }))
    },
  },
}
</script>
