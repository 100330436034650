<template>
  <b-tabs v-model="activeTab">
    <b-tab
      v-for="(translation, language) in translations"
      :key="language"
      :title="getLanguage(language)"
      :label="language"
    >
      <b-input
        v-if="fieldType === 'text'"
        v-model="translation[field]"
        @input="updateValue"
      />
      <b-form-textarea
        v-if="fieldType === 'textarea'"
        v-model="translation[field]"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :rows="rows"
        @input="updateValue"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: 'Translateable',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '...',
    },
    fieldType: {
      type: String,
      default: 'text',
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    rows: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      languages: ['en_US', 'nl_NL', 'fr_FR', 'de_DE'],
      translations: {},
      activeTab: 0,
    }
  },
  watch: {
    value: {
      handler() {
        this.init()
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    getLanguage(language) {
      const languages = {
        en_US: 'English',
        nl_NL: 'Dutch',
        fr_FR: 'French',
        de_DE: 'German',
      }
      return languages[language]
    },
    init() {
      this.translations = this.value.translations ?? {}
      // eslint-disable-next-line no-restricted-syntax
      for (const language of this.languages) {
        if (!(language in this.translations)) {
          this.translations[language] = {}
          this.translations[language][this.field] = ''
        }
      }
      if (!('en_US' in this.translations)) {
        this.translations.en_US = {}
      }
      this.translations.en_US[this.field] = this.value[this.field]
      let i = 0
      Object.keys(this.translations).forEach(language => {
        console.log(language)
        if (language === 'en_US') {
          this.activeTab = i
        }
        i += 1
      })
      console.log(this.activeTab)
    },
    updateValue() {
      const updatedValue = this.value
      const newTranslations = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const language in this.translations) if (this.translations[language][this.field] !== '') newTranslations[language] = this.translations[language]

      updatedValue.translations = newTranslations
      updatedValue[this.field] = this.translations.en_US[this.field]
      this.$emit('input', updatedValue)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .tab-content {
  padding: 0;
}
</style>
