<template>
  <b-card
    title="Location"
  >
    <LocationForm
      v-model="location"
      @save="save"
    />
    <b-row class="dropdown-divider mb-2" />
    <div class="text-right">
      <b-button
        right
        variant="primary"
        @click="save"
      >
        <feather-icon
          icon="SaveIcon"
        />
        Save
      </b-button>
    </div>
  </b-card>
</template>

<script>
import LocationForm from '@/components/account/LocationForm.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Location',
  components: {
    LocationForm,
    FeatherIcon,
  },
  computed: {
    ...mapGetters({
      location: 'authentication/location',
    }),
  },
  methods: {
    save() {
      this.location.update().then(val => {
        this.$bvToast.toast(val.data.message, {
          title: 'Saved',
          autoHideDelay: 4000,
          toaster: 'b-toaster-top-center',
          solid: true,
          variant: 'success',
        })
      })
    },
  },
}
</script>
