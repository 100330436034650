<template>
  <div>
    <button
      class="btn btn-primary"
      @click="selectImage"
    >
      <slot />
    </button>
    <input
      ref="fileInput"
      type="file"
      style="display: none"
      @change="onFileChange"
    >

    <div
      v-if="showCropper"
      class="cropper-dialog zindex-4"
    >
      <cropper
        ref="cropper"
        :src="imageUrl"
        :stencil-props="{ aspectRatio: 16/9 }"
      />
      <button
        class="btn btn-primary mt-1"
        @click="cropImage"
      >
        Done
      </button>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { requests } from '@/_helpers'
import { cPhoto } from '@/_models'

export default {
  components: {
    Cropper,
  },
  data() {
    return {
      imageUrl: null,
      showCropper: false,
    }
  },
  methods: {
    selectImage(e) {
      e.preventDefault()
      this.$refs.fileInput.click()
    },
    onFileChange(e) {
      const file = e.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = loaded => {
          this.imageUrl = loaded.target.result
          this.showCropper = true
        }
        reader.readAsDataURL(file)
      }
    },
    async cropImage(e) {
      e.preventDefault()
      const { canvas } = this.$refs.cropper.getResult()
      try {
        const data = new FormData()
        canvas.toBlob(async blob => {
          data.append('file', blob, 'partner-photo.png')
          const response = await requests.upload('files/partner-photo', data)
          this.$emit('upload-complete', cPhoto.fromJSON(response.data))
        })
      } catch (error) {
        this.$emit('upload-failed', error)
      } finally {
        this.showCropper = false
      }
    },
  },
}
</script>

<style scoped>
.cropper-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cropper-dialog > * {
  max-width: 1200px;
  max-height: 600px;
}
</style>
